  .slider-container {
    --progress-bar-height: 4px;
  }
  
  .slider-container {
    position: relative;
    width: 100%;
  }
  
  /* Background */
  .slider-container::before {
    content: '';
    background-color: #7BC7FF;
    width: 99%;
    height: 125px;
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .azul-slider-container {
    --progress-bar-height: 4px;
  }
  
  .azul-slider-container {
    position: relative;
    width: 100%;
  }

  .azul-slider-container::before {
    content: '';
    background-color: #FFFFFF;
    width: 95%;
    height: 125px;
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  /* Custom Progress Bar */
  .progress-bar-cover {
    background-color: #004070;
    width: 0%;
    height: 125px;
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }
  
  /*  Hide Original */
  .range {
    -webkit-appearance: none;
    background-color: rgba(240, 9, 9, 0.397);
    height: 10px;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
  }


  /* SLIDE GRANDE */
  .slider-container-gr {
    --progress-bar-height: 4px;
    margin-top: 120px;
  }
  
  .slider-container-gr {
    position: relative;
    width: 100%;
  }
  
  /* Background */
  .slider-container-gr::before {
    content: '';
    background-color: #7BC7FF;
    width: 99%;
    height: 300px;
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .gr-progress-bar-cover {
    background-color: #004070;
    width: 0%;
    height: 300px;
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }

  /* SLIDE MEDIO */
  .md-slider-container {
    --progress-bar-height: 4px;
  }
  
  .md-slider-container {
    position: relative;
    width: 100%;
  }
  
  /* Background */
  .md-slider-container::before {
    content: '';
    background-color: #7BC7FF;
    width: 99%;
    height: 125px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }